import React from "react"
import Layout from "../components/layout"
import Cta from "../components/cta"
import { graphql } from "gatsby"
import Markdown from "react-markdown"
import CardArticle from "../components/card-article"

export const query = graphql`
  query HomePageQuery {
    strapiHomepage {
      introduction
      titleHighlight
      ctasTopOfPage {
        id
        description
        title
        buttonText
        link
        color
        buttonPingAnimation
        externalLink
        visible
        fixedId
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
    allStrapiArticle(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          strapiId
          slug
          title
          description
          publishedAt(formatString: "DD MMMM YYYY")
          category {
            name
          }
          image {
            childImageSharp {
              fluid(maxWidth: 500, maxHeight: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          author {
            name
            picture {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function Home({ data, location }) {
  const seo = { ...data.strapiHomepage.seo, url: location.pathname }

  return (
    <Layout seo={seo}>
      <div className="text-xl">
        <div className="container mx-auto flex items-center	flex-col px-4 lg:px-12 mt-12">
          <div class="grid grid-cols-12 mb-12 sm:mb-18">
            <div class="col-span-12 sm:col-span-9">
              <h1 class="text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl font-bold mb-8">
                <Markdown
                  source={data.strapiHomepage.titleHighlight}
                  escapeHtml={false}
                />
              </h1>
              <Markdown
                className="font-medium"
                source={data.strapiHomepage.introduction}
                escapeHtml={false}
              />
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 lg:px-12 mb-8 md:mb-12">
          <div className="grid grid-cols-12 md:gap-12">
            {data.strapiHomepage.ctasTopOfPage.map((value, index) => {
              if (value?.visible) return <Cta ctaData={value} key={index} />
            })}
          </div>
        </div>
        <div className="container mx-auto px-4 lg:px-12 mb-12">
          <h2 className="mb-8 text-4xl font-bold">Blog</h2>
          <div className="md:flex md:space-x-8 mb-8 ">
            {data.allStrapiArticle.edges.map((value, index) => {
              return <CardArticle article={value} key={index} />
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
