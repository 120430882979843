import React from "react"
import { Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"

export default function CardArticle({ article }) {
  return (
    <article className="flex-1 mb-12 md:mb-0">
      <Link to={`/blog/article/${article.node.slug}/`}>
        <BackgroundImage
          className="h-52 mb-4"
          fluid={article.node.image.childImageSharp.fluid}
          alt=""
        />
        <h3 className="text-2xl font-bold">{article.node.title}</h3>
        <p className="text-lg text-gray-500 mb-4">{article.node.description}</p>
        {/* <p className="text-lg text-gray-500">{article.node.publishedAt}</p> */}
      </Link>
    </article>
  )
}
