import React from "react"
import { Link } from "gatsby"

export default function Cta({ ctaData, className }) {
  return (
    <div
      className={`col-span-12 sm:col-span-6 mb-12 md:mb-0 ${className} ${
        ctaData.color == "green" ? "text-white" : "text-black"
      } `}
    >
      <div
        className={`rounded-md p-8 shadow-xl h-full ${
          ctaData.color == "green" ? "bg-green-500" : "bg-white"
        } flex flex-col justify-between border-gray-50 border`}
      >
        <span className="text-3xl font-bold mb-6 block">{ctaData.title}</span>
        <p className="mb-6">{ctaData.description}</p>

        <span className="relative inline-flex rounded-md shadow-sm self-start w-full sm:w-auto">
          {!!ctaData.link && !ctaData.externalLink && (
            <Link
              to={ctaData.link}
              className={` ${
                ctaData.color == "green" ? "border-white" : "border-black"
              } w-full sm:w-auto font-bold text-lg text-center border-4  rounded-md py-2 px-12 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start`}
            >
              {ctaData.buttonText}
            </Link>
          )}

          {!!ctaData.link && !!ctaData.externalLink && (
            <a
              href={ctaData.link}
              className={` ${
                ctaData.color == "green" ? "border-white" : "border-black"
              } w-full sm:w-auto font-bold text-lg text-center border-4  rounded-md py-2 px-12 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start`}
            >
              {ctaData.buttonText}
            </a>
          )}

          {!!ctaData.fileDownload && !!ctaData.fileDownload.publicURL && (
            <a
              href={ctaData.fileDownload.publicURL}
              className={` ${
                ctaData.color == "green" ? "border-white" : "border-black"
              } w-full sm:w-auto font-bold text-lg text-center border-4  rounded-md py-2 px-12 transition duration-500 ease select-none hover:text-white hover:bg-pink-800 focus:outline-none hover:border-pink-800 focus:shadow-outline self-start`}
              download
            >
              {ctaData.buttonText}
            </a>
          )}

          {!!ctaData.buttonPingAnimation && (
            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
          )}
        </span>
      </div>
    </div>
  )
}
